import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { GetQueryParameter } from '../../Utilities/Url';
import { GetContext } from '../../Utilities/TeamsContextHandler';
import { useHistory } from 'react-router-dom';

const de = require('../../Languages/de.json');
const en = require('../../Languages/en.json');
const fr = require('../../Languages/fr.json');

interface ILocalizationProviderProps {
    children: React.ReactNode;
}

const LocalizationProvider: React.FC<ILocalizationProviderProps> = ({ children }) => {
    const [locale, setLocale] = useState<string>('en');
    const history = useHistory();

    useEffect(() => {
        GetContext(context => {
            setLocale(context.locale);
        });
    }, []);

    const getMessages = (locale: string) => {
        switch (locale) {
            case 'de':
                return de;
            case 'fr':
                return fr;
            default:
                return en;
        }
    };

    useEffect(() => {
        setLocale(GetQueryParameter('locale',en));
    }, [history.location.search]);

    const getCurrentLocale = () => {
        if (locale) return locale;

        const urlLocale = GetQueryParameter('locale');
        if (urlLocale?.startsWith('de')) {
            return 'de';
        }
        if (urlLocale?.startsWith('fr')) {
            return 'fr';
        }
        return 'en';
    };

    const currentLocale = getCurrentLocale();
    const messages = getMessages(currentLocale);

    return (
        <IntlProvider locale={currentLocale} messages={messages}>
            {children}
        </IntlProvider>
    );
};

export default LocalizationProvider;
