import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import style from "../../AboutS2S.module.scss";
import Counter from '../../../../../../GlobalComponents/Counter/Counter';
import GeneralInformation from "../../../../../GeneralInformations.json"

interface FrameProps {
}

class Frame extends React.Component<FrameProps & WrappedComponentProps, {}> {
    render() {
        return (
            <div className={style.frameContainer}>
                <div className={style.frameData}>
                    <div className={style.topText}><Counter number={GeneralInformation.Quality.Years.toString()} duration={"3"}></Counter>+</div>
                    <p className={style.bottomText}>{this.props.intl.formatMessage({id: 'Common.YearsExperience', defaultMessage:'Years Experience'}).toUpperCase()}</p>
                </div>

                <div className={style.frameData}>
                    <div className={style.topText}><Counter number={GeneralInformation.Quality.Partners.toString()} duration={"3"}></Counter>+</div>
                    <p className={style.bottomText}>{this.props.intl.formatMessage({id: 'Common.Partners', defaultMessage:'Partners'}).toUpperCase()}</p>
                </div>

                <div className={style.frameData}>
                    <div className={style.topText}><Counter number={GeneralInformation.Quality.Countries.toString()} duration={"3"}></Counter>+</div>
                    <p className={style.bottomText}>{this.props.intl.formatMessage({id: 'Common.Countries', defaultMessage:'Countries'}).toUpperCase()}</p>
                </div>

                <div className={style.frameData}>
                    <div className={style.topText}><Counter number={GeneralInformation.Quality.Installations.toString()} duration={"3"}></Counter>K</div>
                    <p className={style.bottomText}>{this.props.intl.formatMessage({id: 'Common.Installations', defaultMessage:'Installations'}).toUpperCase()}</p>
                </div>
            </div>
        );
    }
}

export default injectIntl(Frame);
