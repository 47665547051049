import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import Selection from "./components/Sections/Sections";
import style from "./getStarted.module.scss";
import * as icon from "@fluentui/react-northstar";
import { Card } from "./components/CardItem/CardItem";
import CustomHeader from "./components/Header/CustomHeader";
import { getSelectedProductInformation } from "../../../../Utilities/Product";

interface GetStartedProps {
}

class GetStarted extends React.Component<
  GetStartedProps & WrappedComponentProps
> {
  render() {
    return (
      <>
        <CustomHeader></CustomHeader>
        <div className={style.informationContainer}>
          {getSelectedProductInformation().getStarted.specifications.map(
            (data, index) => {
              const Icon = icon?.[data.icon];
              return (
                <Card
                  key={index}
                  translationHeaderID={data.title}
                  translationTextID={data.text}
                  title={data.altTitle}
                  text={data.altText}
                  icon={<Icon outline />}
                />
              );
            }
          )}
        </div>
        <div className={style.selectionContainer}>
          <Selection/>
        </div>
      </>
    );
  }
}

export default injectIntl(GetStarted);
