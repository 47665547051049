import { Loader } from "@fluentui/react-northstar";
import React, { useEffect, useRef, useState } from "react";
import { FunctionComponent } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// import "react-pdf/dist/Page/AnnotationLayer.css";
// import "react-pdf/dist/Page/TextLayer.css";
import products from "../../Products.json";
import { getPdfWorkerPath } from "../../../../Utilities/Pdf";
import { checkLanguageGerman } from "../../../../Utilities/Language";

interface WorkshopProps {}

const Workshop: FunctionComponent<WorkshopProps> = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = getPdfWorkerPath();

    const handleResize = () => {
      if (containerRef.current) {
        setWidth(containerRef.current.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    setTimeout(() => setWidth(containerRef.current.offsetWidth),1); //needed for getting container size minus the scrollbar size else it takes width without the scrollbar
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isLoaded]);

  return (
    <div ref={containerRef} style={{ width: "100%" }}>
      <Document
        file={
          checkLanguageGerman()
            ? products.General.workshopPdf.german
            : products.General.workshopPdf.english
        }
        loading={<Loader />}
        error={"Failed to load PDF file."}
        onLoadSuccess={() => setIsLoaded(true)}
      >
        {width > 0 && isLoaded && <Page key={0} width={width} pageIndex={0} />}
      </Document>
    </div>
  );
};

export default Workshop;
