import React, { Children } from 'react';
import style from "./Dropdown.module.scss"

type DropdownProps = {
  children:React.ReactNode,
  title:React.ReactNode
}

const Dropdown = ({ children, title }:DropdownProps) => {
  return (
    <div className={style.dropdown}>
      <div>{title}</div>
        <div className={style.dropdownContent}>
          {Children.map(children,(child) =><div className={style.dropdownItem}>{child}</div>)}
        </div>
    </div>
  );
};

export default Dropdown;