let teamsContext = null;
let isInitialized = false;

export const GetContext = (contextCallback: (context: microsoftTeams.Context) => void) => {
  if (!teamsContext && !isInitialized) {
    microsoftTeams.initialize(() => {
      microsoftTeams.getContext((context) => {
        teamsContext = context;
        contextCallback(teamsContext);
      });
    });
  }
  else if (teamsContext) {
    contextCallback(teamsContext);
  }
  isInitialized = true;
};

export const isInTeams = ()=> {
  if (!isInitialized) {
    console.error("TeamsContextHandler is not initialized");
    return false;
  }
  if (!teamsContext) {
    return false;
  }
  return true;
}