import * as React from 'react';
import style from "../../AboutS2S.module.scss";
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

interface OurStoryProps {
}

class OurStory extends React.Component<OurStoryProps & WrappedComponentProps> {
    render() {
        return (
            <>
                <h2><FormattedMessage id="AboutS2S.OurStory.Title" defaultMessage="Our Story" /></h2>
                <div className={style.textContainer}>
                    <div className={style.row4}>
                        <p className={style.textLightmode}><FormattedMessage id="AboutS2S.OurStory.TopLeft" defaultMessage="In 2009, we started to develop our first governance program, the Collaboration Manager for SharePoint On-Premises 2010. Today, with 10 years’ experience in creating products for Microsoft 365, we released the easiest tool for governance in Microsoft Teams – the Teams Manager." /></p>
                        <p className={style.textLightmode}><FormattedMessage id="AboutS2S.OurStory.BottomLeft" defaultMessage="Our products focus on improving your work with Microsoft 365 by adding collaboration and governance features for Microsoft SharePoint and Microsoft Teams. Our software has been installed more than 40,000 times by customers in 72 countries. We are a Microsoft Gold Partner and have received the Rencore Development Best Practices certification." /></p>
                    </div>
                    <div className={style.row4}>
                        <p className={style.textLightmode}><FormattedMessage id="AboutS2S.OurStory.TopRight" defaultMessage="Solutions2Share was founded in 2011 close to Nuremberg in Germany. We have grown from a one-man show to an innovative team of 25 specialists in several locations in Germany, the Netherlands, and Serbia, connected through our joint passion for our work." /></p>
                        <p className={style.textLightmode}><FormattedMessage id="AboutS2S.OurStory.BottomRight" defaultMessage="We accompanied Microsoft Teams and SharePoint with our software tools from the very start. Our name is our motto: We build solutions to share, to make governance of Microsoft tools easier and to help you collaborate and connect to your colleagues all around the world." /></p>
                    </div>
                </div>
            </>
        );
    }
}

export default injectIntl(OurStory);