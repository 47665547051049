import * as React from "react";
import cssStyles from "./GiveUsFeedback.module.scss";
import BackgroundPanel from "../../../../GlobalComponents/BackgroundPanel/BackgroundPanel";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import SiteHeading from "../../../../GlobalComponents/SiteHeading/SiteHeading";
import {
  Button,
  Provider,
  Text,
} from "@fluentui/react-northstar";
import ScoreSelector from "../../../../GlobalComponents/ScoreSelector/ScoreSelector";
import TextArea from "../../../../GlobalComponents/TextArea/TextArea";

interface Props {
  productName: string;
}

class GiveUsFeedback extends React.Component<Props&WrappedComponentProps, {}> {
  render() {
    return (
      <Provider
        style={{ backgroundColor: "transparent","margin": "24px 0px 0px 24px" }}
      >
        <SiteHeading
          headingId="GiveUsFeedback.Heading"
          headingAlternativeText="Give us feedback*"
          descriptionId="GiveUsFeedback.Subheading"
          descriptionAlternativeText="Your feedback will help us improve the product.*"
        />
        <BackgroundPanel className={cssStyles.GiveUsFeedbackBackground}>
          <Text className={cssStyles.Text}
            content={ this.props.intl.formatMessage({
              id:"GiveUsFeedback.Satisfaction",
              defaultMessage:"How satisfied are you with {0}?"
            }).replace("{0}", this.props.productName)
            }
            
          />
          <ScoreSelector className={cssStyles.ScoreSelector} />
          <Text className={cssStyles.Text}
            content={
              <FormattedMessage
                id="GiveUsFeedback.Improvement"
                defaultMessage="Tell us how we can improve the product."
              ></FormattedMessage>
            }
          />
          <TextArea className={cssStyles.TextArea} placeholderTextId="GiveUsFeedback.Placeholder" altPlaceholderText="Add your message here..."/>
          <div className={cssStyles.buttonContainer}>
            <Button
              content={
                <FormattedMessage
                  id="Common.Submit"
                  defaultMessage="Submit"
                ></FormattedMessage>
              }
              primary
            />
          </div>
        </BackgroundPanel>
      </Provider>
    );
  }
}

export default injectIntl(GiveUsFeedback);
