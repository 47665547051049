import * as React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import style from "./Person.module.scss";

interface TeamComponentProps {
  name: string;
  desc?: string;
}

class TeamComponent extends React.Component<
  TeamComponentProps & WrappedComponentProps
> {
  render() {
    return (
      <div className={style.Person}>
        <img
          className={style.image}
          draggable={false}
          src={window.location.origin + `/img/team/${this.props.name}.webp`}
          alt={""}
        ></img>
        <h3>{this.props.name}</h3>
        <p className={style.nameText}>{this.props.desc}</p>
      </div>
    );
  }
}

export default injectIntl(TeamComponent);
