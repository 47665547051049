import * as React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import style from "../../AboutS2S.module.scss";

class Partner extends React.Component<{} & WrappedComponentProps> {
    render() {
        return (
            <div className={style.partnerContainer}>
                <div id={style.pic1}></div>
                <div id={style.pic2}></div>
            </div>
        );
    }
}

export default injectIntl(Partner);