import React, { FunctionComponent } from "react";
import cssStyles from "./OverviewCard.module.scss";
import { Text } from "@fluentui/react-northstar"
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
interface OverviewCardProps {
    TitleTranslationId: string,
    DescriptionTranslationId: string,
    Company: string,
    Image: string,
    CategoryTranslationId: string,
    TargetUrl?: string,
    LinkParams?: string
}



const OverviewCard: FunctionComponent<OverviewCardProps> = (props: OverviewCardProps) => {
    return (
        <Link to={{ pathname: props.TargetUrl || "#", search: props.LinkParams }} className={cssStyles.OverviewCardLink} draggable="false">
            <div className={cssStyles.OverviewCard} id={props.TargetUrl !== undefined && props.TargetUrl !== "" && props.TargetUrl !== "/" && props.TargetUrl !== "#" ? cssStyles.Clickable : ""}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div className={cssStyles.ImageContainer}>
                        <img src={props.Image} alt={props.Image} className={cssStyles.Image} draggable={false} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Text className={cssStyles.OverviewCardTitle}><FormattedMessage id={props.TitleTranslationId} defaultMessage={props.TitleTranslationId} /></Text>
                        <Text className={cssStyles.OverviewCardCompany}>{props.Company}</Text>
                    </div>
                </div>
                <Text className={cssStyles.OverviewCardDescription}><FormattedMessage id={props.DescriptionTranslationId} defaultMessage={props.DescriptionTranslationId} /></Text>
                <Text className={cssStyles.OverviewCardCategory}><FormattedMessage id={props.CategoryTranslationId} defaultMessage={props.CategoryTranslationId} /></Text>
            </div>
        </Link>
    );
}

export default OverviewCard;