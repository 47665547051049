import cssStyle from "./BackgroundPanel.module.scss"
import React,{ FunctionComponent } from "react";

interface BackgroundPanelProps {
    children:React.ReactNode
    primary?:boolean,
    className?:string
}
 
const BackgroundPanel: FunctionComponent<BackgroundPanelProps> = ({children,primary,className}) => {
    return (
        <div className={[primary?cssStyle.Primary:"",cssStyle.BackgroundPanel,className].join(" ")}>
            {children}
        </div>
    );
}
 
export default BackgroundPanel;