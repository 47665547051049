
import products from "../MainTab/ProductWindow/Products.json"
let selectedProduct = "";
let previousSelectedProduct = "";
export function getSelectedProductName() {
    return selectedProduct;
}

export function getSelectedProductInformation() {
    if(selectedProduct === "") {
        return null;
    }

    return products.Products[selectedProduct];
}

export function getProductInformation(product: string) {
    return products.Products[product];
}

export function getPreviousSelectedProductName() {
    return previousSelectedProduct;
}

export function setSelectedProduct(product: string) {
    previousSelectedProduct = selectedProduct;
    selectedProduct = product;
}

export function getGeneralInformation() {
    return products.General;
}