import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import style from "../../AboutS2S.module.scss";


interface HeadPictureProps {
}

interface HeadPictureState {

}

class HeadPicture extends React.Component<HeadPictureProps & WrappedComponentProps, HeadPictureState> {
    render() {
        return (
            <div id={style.picContainer}>
                <div id={style.componentContainer}>

                    <img id={style.mainLogo} src={window.location.origin+"/img/General/logo.png"} draggable={false} alt='Logo'></img>

                    <div id={style.textContainer}>
                        <p id={style.weares2sText}><FormattedMessage id="AboutS2S.Header.Title" defaultMessage="We are Solutions2Share" /></p>
                        <p id={style.s2sofferaText}><FormattedMessage id="AboutS2S.Header.Description" defaultMessage="Solutions2Share offers Services ans Solutions around Microsoft Teams, Office 365 and SharePoint" /></p>
                    </div>

                </div>
            </div>
        );
    }
}

export default injectIntl(HeadPicture);