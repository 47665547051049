import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import Dropdown from "../Dropdown/Dropdown";
import LanguageJson from "../../MainTab/Languages.json";
import { Link, useHistory } from "react-router-dom";
import cssStyle from "./LanguageDropdown.module.scss";
import { ChevronDownIcon } from "@fluentui/react-northstar";
import { FormattedMessage } from "react-intl";
import { GetQueryParameter, changeQueryParameter } from "../../Utilities/Url";

interface LanguageDropdownProps {}

const LanguageDropdown: FunctionComponent<LanguageDropdownProps> = (
  props: LanguageDropdownProps
) => {
  const [language, setLanguage] = useState("en");

  return (
    <Dropdown
      title={
        <div className={cssStyle.title}>
          <FormattedMessage
            id={"Language." + language}
            defaultMessage={LanguageJson[language].name}
          ></FormattedMessage>
          <ChevronDownIcon className={cssStyle.icon}></ChevronDownIcon>
        </div>
      }
    >
      <div className={cssStyle.dropdownBackground}>
        {Object.keys(LanguageJson).map((Language) => (
          <Link
            key={Language}
            to={{pathname: window.location.pathname, search: changeQueryParameter("locale", Language)}}
            onClick={() => setLanguage(Language)}
            className={cssStyle.languageName}
          >
            <FormattedMessage
              id={"Language." + Language}
              defaultMessage={LanguageJson[Language].name}
            ></FormattedMessage>
          </Link>
        ))}
      </div>
    </Dropdown>
  );
};

export default LanguageDropdown;
