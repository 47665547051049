export enum ClientType {
	mobile,
	desktop,
	ssr,
}

export abstract class Functions {
	public static GetClientType = (userAgent: NavigatorID['userAgent']) => {
		let isAndroid = Boolean(userAgent.match(/Android/i));
		let isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
		let isOpera = Boolean(userAgent.match(/Opera Mini/i));
		let isWindows = Boolean(userAgent.match(/IEMobile/i));
		let isSSR = Boolean(userAgent.match(/SSR/i));
		let clientType: ClientType = ClientType.ssr;
		if (Boolean(isAndroid || isIos || isOpera || isWindows)) clientType = ClientType.mobile;
		else if (Boolean(!isSSR)) clientType = ClientType.desktop;

		return clientType;
	};
}
