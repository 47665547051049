import React from "react";
import cssStyles from "./SiteHeading.module.scss";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

interface SiteHeadingProps {
    headingId: string
    descriptionId: string
    headingAlternativeText?: string
    descriptionAlternativeText?: string
}

const SiteHeading: FunctionComponent<SiteHeadingProps> = ({headingId, descriptionId, headingAlternativeText, descriptionAlternativeText}) => {
  return (
    <>
      <h1 className={cssStyles.userHeading}>
        <FormattedMessage
          id={headingId}
          defaultMessage={headingAlternativeText}
        />
      </h1>
      <span className={cssStyles.userHeadingDescription}>
        <FormattedMessage
          id={descriptionId}
          defaultMessage={descriptionAlternativeText}
        />
      </span>
    </>
  );
};

export default SiteHeading;
