import React, { useState, useEffect } from "react";
import style from "./Sections.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { CheckmarkCircleIcon, Text } from "@fluentui/react-northstar";
import { 
  getPreviousSelectedProductName, 
  getSelectedProductInformation, 
  getSelectedProductName 
} from "../../../../../../Utilities/Product";

const Sections: React.FC = () => {
  const [page, setPage] = useState(0);
  const intl = useIntl();

  const onClickEvent = (value: any) => {
    setPage(Number(value.id));
  };

  useEffect(() => {
    if (getPreviousSelectedProductName() !== getSelectedProductName()) {
      setPage(0);
    }
  }, [getPreviousSelectedProductName(), getSelectedProductName()]);

  const renderButtonBar = () => {
    return getSelectedProductInformation().getStarted.sections.map((value) => (
      <span
        className={[
          style.Button,
          Number(value.id) === page ? style.activeButton : "",
        ].join(" ")}
        key={value.id}
        onClick={() => onClickEvent(value)}
      >
        <FormattedMessage
          id={value.navTitle}
          defaultMessage="ERROR: VALUE NOT FOUND"
        />
      </span>
    ));
  };

  const renderChecks = () => {
    const sections = getSelectedProductInformation().getStarted.sections[page];
    return (
      <ul className={style.SectionsList}>
        {sections.checkmarks?.map((value, index) => (
          <li className={style.SectionsListItem} key={index}>
            <CheckmarkCircleIcon outline className={style.listIcon} />
            <div className={style.SectionsListItemText}>
              <FormattedMessage
                id={value}
                defaultMessage="TEXT NOT FOUND"
              />
            </div>
          </li>
        ))}
      </ul>
    );
  };

  const selectedSection = getSelectedProductInformation().getStarted.sections[page];

  return (
    <div className={style.SectionsBar}>
      <div className={style.buttonBar}>
        {getSelectedProductInformation().getStarted.sections.length > 1 && renderButtonBar()}
      </div>
      <div className={style.items}>
        <img
          draggable={false}
          className={style.leftSide}
          src={`${window.location.origin}${selectedSection.img}`}
          alt="Product"
        />
        <div className={style.rightSide}>
          <Text as="h3" className={style.title} key={page}>
            <FormattedMessage
              id={selectedSection.title}
              defaultMessage="TEXT NOT FOUND"
            />
          </Text>
          <Text style={{ whiteSpace: "pre-line" }}>
            <FormattedMessage
              id={selectedSection.text}
              defaultMessage="TEXT NOT FOUND"
            />
          </Text>
          {renderChecks()}
        </div>
      </div>
    </div>
  );
};

export default Sections;
