import * as React from 'react';
import cssStyles from "./Footer.module.scss"
import FooterItem from './FooterItem/FooterItem';
interface FooterProps {
    
}
 
interface FooterState {
    
}
 
class Footer extends React.Component<FooterProps, FooterState> {
    state = {  }
    render() { 
        return ( 
            <div className={cssStyles.Footer}>
                <div className={cssStyles.Copyright}>
                    Copyright {new Date().getFullYear()} © Solutions2Share GmbH
                </div>
                <div className={cssStyles.FooterLinks}>
                    <FooterItem className={cssStyles.FooterItem} webUrl='https://www.solutions2share.com/privacy-policy/' multilingualTextId='footer.PrivacyPolicy' defaultText='Privacy Policy'></FooterItem>
                    <FooterItem className={cssStyles.FooterItem} webUrl='https://www.solutions2share.com/imprint/' multilingualTextId='footer.Imprint' defaultText='Imprint'></FooterItem>
                </div>
            </div>
        );
    }
}
 
export default Footer;