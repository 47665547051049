import { Button, Flex, PlayIcon, PopupIcon, Text } from "@fluentui/react-northstar";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { ClientType, Functions } from "../../../../../../GlobalComponents/Functions";
import style from "./CustomHeader.module.scss"
import { checkLanguageGerman } from "../../../../../../Utilities/Language";
import { getSelectedProductInformation } from "../../../../../../Utilities/Product";

interface HeaderProps {
}

const CustomHeader: FunctionComponent<HeaderProps> = () => {
  const [isMobile] = useState(Functions.GetClientType(navigator.userAgent)===ClientType.mobile)
  return (
    <header className={style.header}>
      <div id={style.LogoWrapper}>
        {getSelectedProductInformation().information.logo ? <img draggable={false} id={style.Logo} src={window.location.origin + getSelectedProductInformation().information.logo} alt="Logo" /> : <div className={style.logoHolder}><img className={style.icon} draggable={false} src={window.location.origin + getSelectedProductInformation().information.icon} alt="Logo" /><Text weight="semibold" size="small" content="" id={style.LogoText}>{getSelectedProductInformation().information.title}</Text></div>}
      </div>
      <div id={style.HeaderWrapper}>
        <Flex wrap vAlign="center" hAlign="center" gap="gap.smaller" style={{ justifyContent: "center", width: "100%" }}>
          <div id={style.HeaderControlArea}>
            <div>

              {/*CustomHeader Heading */}
              <Text
                as="h2"
                content={<div className={style.headerTitle}><FormattedMessage id={getSelectedProductInformation().getStarted.header.titleId} defaultMessage={getSelectedProductInformation().getStarted.header.altTitle} ></FormattedMessage></div>}
                id={style.HeaderTitle}
              />

              {/*Info Text to the heading  */}
              <Text
                weight="semilight"
                content={<div className={style.description}><FormattedMessage id={getSelectedProductInformation().getStarted.header.descriptionId} defaultMessage={getSelectedProductInformation().getStarted.header.altDescription} ></FormattedMessage></div>}
              />

              <Flex gap="gap.smaller" id={style.HeaderButtons}>

                {/* Schedule a Demo Button */}
                <Button content={
                  <>
                    <PopupIcon outline size="medium" style={{ "paddingRight": "5px", "paddingBottom": "2px" }} />
                    <FormattedMessage id={getSelectedProductInformation().getStarted.header.primaryButtonTextId} defaultMessage={getSelectedProductInformation().getStarted.header.primaryButtonText} ></FormattedMessage>
                  </>
                } primary onClick={() => { checkLanguageGerman() ? window.open(getSelectedProductInformation().getStarted.header.german.primaryButtonLink, "_blank") : window.open(getSelectedProductInformation().getStarted.header.english.primaryButtonLink, "_blank") }} />
                {
                  getSelectedProductInformation().getStarted.header.secondaryButtonTextId && !isMobile &&
                  <Button secondary content={
                    <>
                      <PopupIcon outline size="medium" style={{ "paddingRight": "5px", "paddingBottom": "2px" }} />
                      <FormattedMessage id={getSelectedProductInformation().getStarted.header.secondaryButtonTextId} defaultMessage={getSelectedProductInformation().getStarted.header.secondaryButtonText} ></FormattedMessage>
                    </>
                  } onClick={() => {
                    checkLanguageGerman() ? window.open(getSelectedProductInformation().getStarted.header.german.secondaryButtonLink, "_blank") : window.open(getSelectedProductInformation().getStarted.header.english.secondaryButtonLink, "_blank")
                  }} />}
              </Flex>

            </div>
          </div>

          {/* CustomHeader Video */}
          <div id={style.headerVideo} style={{ backgroundImage: `url(${window.location.origin + getSelectedProductInformation().getStarted.header.image})` }}>
            {getSelectedProductInformation().getStarted.header.german.videoLink && getSelectedProductInformation().getStarted.header.english.videoLink && <div id={style.GetStartedVideoPlayButton} onClick={() => { checkLanguageGerman() ? window.open(getSelectedProductInformation().getStarted.header.german.videoLink, "_blank") : window.open(getSelectedProductInformation().getStarted.header.english.videoLink, "_blank") }}><PlayIcon size="large" id={style.GetStartedVideoPlayButtonIcon} /></div>}
          </div>

        </Flex>
      </div>
    </header>
  );
}

export default CustomHeader;