import { FunctionComponent } from "react";
import cssStyles from "./SquareButton.module.scss";
import React from "react";

interface SquareButtonProps {
    text: string
    onClick: () => void,
    active?: boolean
}
 
const SquareButton: FunctionComponent<SquareButtonProps> = (props: SquareButtonProps) => {
    return (
        <button className={[cssStyles.squareButton,props.active ? cssStyles.active : ""].join(" ")} onClick={props.onClick}>
            {props.text}
        </button>
    );
}
 
export default SquareButton;