import * as React from "react";
import style from "../../AboutS2S.module.scss";
import TeamComponent from "./Person/Person";
import members from "./team.json";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

interface TeamProps {}

interface TeamState {}

class Team extends React.Component<
  TeamProps & WrappedComponentProps,
  TeamState
> {
  getRandomName(array: string[]) {
    const randIndex = Math.floor(Math.random() * array.length);
    const name = array[randIndex];
    return name;
  }

  renderHeader() {
    return (
      <>
        {members.head.map((value, i) => (
          <TeamComponent key={i} name={value.name} desc={value.description} />
        ))}
      </>
    );
  }

  renderTeam() {
    let teamMembers = members.team;
    let team = [];
    for (const [i] of Object.entries(members.team)) {
    let name = this.getRandomName(teamMembers);
      if (name !== undefined) {
        teamMembers = teamMembers.filter((item) => item !== name);
        team.push(<TeamComponent key={i} name={name} />);
      }
    }
    return team;
  }

  render() {
    return (
      <div className={style.teamContainer}>
        <h2>
          <FormattedMessage
            id="AboutS2S.MeetOurTeam.Title"
            defaultMessage="Meet our team"
          />
        </h2>
        <div id={style.teamText}>
          <p className={style.textLightmode}>
            <FormattedMessage
              id="AboutS2S.MeetOurTeam.Description"
              defaultMessage="Get to know the people behind Solutions2Share. For any requests, we will gladly be at your disposal!"
            />
          </p>
        </div>

        <div className={style.headTeamContainer}>{this.renderHeader()}</div>

        <div className={style.headTeamContainer}>{this.renderTeam()}</div>
      </div>
    );
  }
}

export default injectIntl(Team);
