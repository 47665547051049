import React, { FunctionComponent, ReactElement } from "react";
import style from "./CardItem.module.scss";
import { FormattedMessage } from "react-intl";
//Card Properties
type CardProps = {
  title: string;
  text: string;
  icon: ReactElement;
  translationHeaderID: string;
  translationTextID: string;
};

//Main
export const Card: FunctionComponent<CardProps> = ({
  title,
  text,
  icon,
  translationHeaderID,
  translationTextID,
}: CardProps) => {
  return (
    <div className={style.Card}>
      {/* Icon */}
      <div className={style.Icon}>{icon}</div>

      {/* Title */}
      <div className={style.CardHeader}>
        <FormattedMessage
          id={translationHeaderID}
          defaultMessage={title}
        ></FormattedMessage>
      </div>
      {/* Description */}

      <div className={style.CardText}>
        <FormattedMessage
          id={translationTextID}
          defaultMessage={text}
        ></FormattedMessage>
      </div>
    </div>
  );
};
