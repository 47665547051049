import React, { FunctionComponent, useEffect, useState } from "react";
import { Button, Text, Image, PlayIcon, Provider, teamsDarkV2Theme } from '@fluentui/react-northstar';
import TeamsManagerLogoVideoCard from "./img/TeamsManagerLogoVideoCard.png";
import style from "../../VideoTrainings.module.scss"
import { FormattedMessage } from "react-intl";
import YouTube from 'react-youtube';
import CardBackgroundPanel from "../../../../../../GlobalComponents/CardBackgroundPanel/CardBackgroundPanel";

// Video Card Props
type VideoCardProps = {
  text: string,
  videoID: string,
  translationTextID: string
}

export const VideoCard: FunctionComponent<VideoCardProps> = ({ text, videoID, translationTextID }: VideoCardProps) => {
  const [VideoCardOverlayState, setVideoCardOverlay] = useState(style.DisableVideoCardOverlay)
  const [VideoPlayerState, setVideoPlayerState] = useState([]);

  //Getting ESC Key
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setVideoCardOverlay(style.DisableVideoCardOverlay)
      pauseVideos();
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", escFunction);

    return () => {
      document.removeEventListener("keydown", escFunction);
    };
  });

  function getReadyVideos(event) {
    const player = VideoPlayerState;
    player.push(event.target);
    setVideoPlayerState(player);
  }

  function pauseVideos() {
    VideoPlayerState.forEach((player) => {
      player.pauseVideo();
    });
  }

  return (
    <div>
      {/* Video Card */}
      <CardBackgroundPanel primary>
        <div className={style.VideoCardItemWrapper}>
          <div className={style.VideoCardItem}><Image className={style.TeamsManagerLogoVideoCard} src={TeamsManagerLogoVideoCard}></Image></div>
          <div className={style.VideoButtonArea}>
            <div className={style.VideoCardItem}>
              <div className={style.VideoCardPlayButton} onClick={() => setVideoCardOverlay(style.ShowVideoCardOverlay)}><PlayIcon size="medium" className={style.VideoCardPlayButtonIcon} /></div>
            </div></div>
          <div className={style.VideoCardItem}>
            <Text weight="bold" size="medium"><FormattedMessage id={translationTextID} defaultMessage={text} ></FormattedMessage></Text></div>
        </div>
        </CardBackgroundPanel>

      {/* Video Player Overlay */}
      <div className={VideoCardOverlayState}>
        <div className={style.VideoCardOverlayTitleBar}>
          <div className={style.VideoCardOverlayTitleBarItemWrapper}><Text className={style.VideoCardOverlayTitleBarText} size="larger" content={<FormattedMessage id={translationTextID} defaultMessage={text} ></FormattedMessage>} /><Provider theme={teamsDarkV2Theme}><Button onClick={() => { setVideoCardOverlay(style.DisableVideoCardOverlay); pauseVideos() }} content={<div><FormattedMessage id="TeamsManager.VideoTrainingPage.Cancel" defaultMessage={"Cancel"} ></FormattedMessage></div>} /></Provider></div>
        </div>
        <div className={style.VideoCardOverlayVideoPlayer}>
          <YouTube className={style.VideoCardOverlayVideo} videoId={videoID} onReady={getReadyVideos} opts={{ height: window.innerHeight - 100, width: window.innerWidth }} />
        </div>
      </div>
    </div>
  );
}
