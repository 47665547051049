import { FunctionComponent } from "react";
import BackgroundPanel from "../BackgroundPanel/BackgroundPanel";
import cssStyles from "./CardBackgroundPanel.module.scss";
import React from "react";

interface CardBackgroundPanelProps {
  children: React.ReactNode;
  primary?: boolean;
}

const CardBackgroundPanel: FunctionComponent<CardBackgroundPanelProps> = (
  props
) => {
  return <BackgroundPanel primary={props.primary} className={cssStyles.BackgroundPanel}>{props.children}</BackgroundPanel>;
};

export default CardBackgroundPanel;
