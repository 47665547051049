import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OverviewPage from "./MainTab/OverviewPage/OverviewPage";
import { Product } from "./MainTab/ProductWindow/Product";
import "./App.css";
import ThemeProvider from "./GlobalComponents/ThemeProvider/ThemeProvider";
import LocalizationProvider from "./GlobalComponents/LocalizationProvider/LocalizationProvider";

export default function App() {
  return (
    <ThemeProvider>
      <Router>
        <Switch>
          <LocalizationProvider>
            <Route
              path="/TeamsManager/HelpTab"
              render={() => <Product product={"TeamsManager"} />}
            ></Route>
            <Route
              path="/ExternalUserManager/HelpTab"
              render={() => <Product product={"ExternalUserManager"} />}
            ></Route>
            <Route
              path="/Hyperlinks/HelpTab"
              render={() => <Product product={"HyperLinks"} />}
            ></Route>
            <Route
              path="/MindMap/HelpTab"
              render={() => <Product product={"MindMap"} />}
            ></Route>
            <Route
              path="/FileManager/HelpTab"
              render={() => <Product product={"FileManager"} />}
            ></Route>
            <Route
              path="/Intranet/HelpTab"
              render={() => <Product product={"Intranet"} />}
            ></Route>
            <Route
              path="/CollaborationManager/HelpTab"
              render={() => <Product product={"CollaborationManager"} />}
            ></Route>
            <Route
              path="/AppManager/HelpTab"
              render={() => <Product product={"AppManager"} />}
            ></Route>
            <Route path="/Overview" component={OverviewPage}></Route>
            <Route path="/" component={OverviewPage} exact></Route>
          </LocalizationProvider>{" "}
        </Switch>
      </Router>
    </ThemeProvider>
  );
}
