import * as React from "react";
import cssStyles from "./VideoTrainings.module.scss";
import Videos from "./Videos.json";
import VideoCardUser from "./components/VideoCard/VideoCardUser";
import { Accordion } from "@fluentui/react-northstar";
import SiteHeading from "../../../../GlobalComponents/SiteHeading/SiteHeading";
import { checkLanguageGerman } from "../../../../Utilities/Language";

interface VideoTrainingUserProps {}

function VideoTrainingUser(props: VideoTrainingUserProps) {
  const pannel = [
    {
      key: "1",
      title: <h4 className={cssStyles.ProviderTitle}>Creation Process</h4>,
      content: handleCreation(),
    },
    {
      key: "2",
      title: <h4 className={cssStyles.ProviderTitle}>Approval Process</h4>,
      content: handleApprovalProcess(),
    },
    {
      key: "3",
      title: <h4 className={cssStyles.ProviderTitle}>Extending Lifecycle</h4>,
      content: handleExtendingLifecycle(),
    },
  ];

  function handleCreation() {
    var creation = Videos.Creation.map((video) => (
      <VideoCardUser
        text={video.altText}
        translationTextID={video.translationId}
        videoID={
          checkLanguageGerman() ? video.germanVideoId : video.englishVideoId
        }
      ></VideoCardUser>
    ));
    return <div className={cssStyles.VideoAreaBox}>{creation}</div>;
  }

  function handleApprovalProcess() {
    var approval = Videos.ApprovalProcess.map((video) => (
      <VideoCardUser
        text={video.altText}
        translationTextID={video.translationId}
        videoID={
          checkLanguageGerman() ? video.germanVideoId : video.englishVideoId
        }
      ></VideoCardUser>
    ));
    return <div className={cssStyles.VideoAreaBox}>{approval}</div>;
  }
  function handleExtendingLifecycle() {
    var extendingLifeCycle = Videos.ExtendingLifeCycle.map((video) => (
      <VideoCardUser
        text={video.altText}
        translationTextID={video.translationId}
        videoID={
          checkLanguageGerman() ? video.germanVideoId : video.englishVideoId
        }
      ></VideoCardUser>
    ));
    return <div className={cssStyles.VideoAreaBox}>{extendingLifeCycle}</div>;
  }
  return (
    <div className={cssStyles.userContent}>
      <SiteHeading
        headingId="VideoTrainings.heading"
        headingAlternativeText="Video Training*"
        descriptionId="VideoTrainings.Subheading"
        descriptionAlternativeText="Watch video trainings about the main features of Teams Manager.*"
      />
      <Accordion
        defaultActiveIndex={[0, 1, 2]}
        className={cssStyles.accordion}
        panels={pannel}
      />
    </div>
  );
}

export default VideoTrainingUser;
