import React, { FunctionComponent, useState } from "react";
import SquareButton from "../SquareButton/SquareButton";
import cssStyles from "./ScoreSelector.module.scss"
import { FormattedMessage } from "react-intl";

interface ScoreSelectorProps {
  from?: number;
  to?: number;
  className?: string;
}

const ScoreSelector: FunctionComponent<ScoreSelectorProps> = ({ from = 1, to = 10, className }) => {
  const [selected, setSelected] = useState(from);
  const scores = Array.from({ length: to - from + 1 }, (_, i) => (from + i).toString());

  return (
  <div className={[cssStyles.ScoreSelectorContainer,className].join(" ")}>
    <div className={cssStyles.ScoreSelector}>
      {scores.map((score, index) => (
        <SquareButton key={index} text={score} active={selected === from + index} onClick={() => setSelected(from + index)} />
      ))}
    </div>
    <div className={cssStyles.ScoreTexts}>
      <div><FormattedMessage defaultMessage="Very Dissatisfied" id="ScoreSelector.veryDissatisfied"/></div>
      <div><FormattedMessage defaultMessage="Very Satisfied" id="ScoreSelector.verySatisfied"/></div>
    </div>
    </div>
  );
};

export default ScoreSelector;
