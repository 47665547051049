import { FunctionComponent } from "react";
import React from "react";
import cssStyles from "./Artwork.module.scss";

interface ArtworkProps {
    
}
 
const Artwork: FunctionComponent<ArtworkProps> = () => {
    return (
        <div className={cssStyles.Artwork}>
            <div className={cssStyles.SmallCard} style={{backgroundImage: 'url("/img/Artwork/fm.png")'}}></div>
            <div className={cssStyles.MediumCard} style={{backgroundImage: 'url("/img/Artwork/hl.png")'}}></div>
            <div className={cssStyles.BigCard} style={{backgroundImage: 'url("/img/Artwork/tm.png")'}}></div>
            <div className={cssStyles.MediumCard} style={{backgroundImage: 'url("/img/Artwork/am.png")'}}></div>
            <div className={cssStyles.SmallCard} style={{backgroundImage: 'url("/img/Artwork/eum.png")'}}></div>
        </div>
    );
}
 
export default Artwork;