import { ArrowLeftIcon } from "@fluentui/react-northstar";
import React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { getAllQueryParameters } from "../../Utilities/Url";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";
import ProductsDropdown from "../ProductsDropdown/ProductsDropdown";
import cssStyles from "./Nav.module.scss";

interface PagesNavBarProps {
  show: boolean;
  targetUrl: string;
}

const PagesNavBar: FunctionComponent<PagesNavBarProps> = (
  props: PagesNavBarProps
) => {
  return props.show ? (
    <>
      <h3 className={cssStyles.header}>
        <div className={cssStyles.headerContentWrapper}>
          <div className={cssStyles.backButton}>
            <Link
              replace
              to={{
                pathname: props.targetUrl,
                search: getAllQueryParameters(window.location.href),
              }}
              className={cssStyles.backButtonLink}
            >
              <ArrowLeftIcon />
              <FormattedMessage id="MainTab.Back" defaultMessage="Back" />
            </Link>{" "}
          </div>
          <div className={cssStyles.HelpTitle}>
            <FormattedMessage id="MainTab.Help" defaultMessage="Help*" />
          </div>
        </div>
        <div className={cssStyles.dropdownHolder}>
          <ProductsDropdown
          ></ProductsDropdown>
          <LanguageDropdown></LanguageDropdown>
        </div>
      </h3>
      <hr className={cssStyles.limitLine}></hr>
    </>
  ) : null;
};

export default PagesNavBar;
