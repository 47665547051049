
export const GetQueryParameter = (parameter: string,defaultValue="") => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlParam = urlParams.get(parameter)
  if (urlParam!==null)
    return urlParams.get(parameter)
  return defaultValue;
};

export const changeQueryParameter = (parameter: string, value: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  urlParams.set(parameter, value);
  return urlParams.toString();
};

export function getAllQueryParameters(url):string {
  const queryString = url.split('?')[1];
  if (!queryString) return '';

  const queryParams = new URLSearchParams(queryString);
  let queryParamsString = '';

  for (const [key, value] of queryParams.entries()) {
    queryParamsString += `${key}=${value}&`;
  }

  // Remove the trailing '&' if there are parameters
  if (queryParamsString.length > 0) {
    queryParamsString = queryParamsString.slice(0, -1);
  }
  return queryParamsString;
}

export function setNthUrlPart(url: string, index: number, value: string) {
  const urlParts = url.split('/');
  urlParts[index] = value;
  return urlParts.join('/');
}

export function getNthUrlPart(url: string, index: number) {
  const urlParts = url.split('/');
  return urlParts[index];
}