import * as React from "react";
import style from "./MoreProducts.module.scss";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";

import Products from "./ProductsList.json";
import { HeaderBanner } from "./components/HeaderBanner/HeaderBanner";
import { ProductCard } from "./components/ProductCard/ProductCard";
import { checkLanguageGerman } from "../../../../Utilities/Language";

interface MoreProductsProps {}

class MoreProducts extends React.Component<
  MoreProductsProps & WrappedComponentProps
> {
  render() {
    return (
      <>
        {/* Adding Header Banner module */}
        <HeaderBanner />
        <div className={style.CardGrid}>
          <div className={style.CardGridWrapper}>
            {Products.Categories.map((Category, index) => (
              <div>
                <div className={style.CardColumnTitle}>
                  <FormattedMessage
                    id={Category.CategoryNameId}
                    defaultMessage={Category.CategoryNameAlt}
                  ></FormattedMessage>
                </div>
                {Products.Categories[index].Products.map((Product) => (
                  <ProductCard
                    translationHeaderID={Product.translationHeaderID}
                    translationTextID={Product.translationTextID}
                    title={Product.title}
                    text={Product.text}
                    img={window.location.origin + Product.img}
                    url={
                      checkLanguageGerman()
                        ? Product.germanUrl
                        : Product.englishUrl
                    }
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default injectIntl(MoreProducts);
