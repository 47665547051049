import React from "react";
import "./UsefulLinksModule.scss";
import { FormattedMessage } from "react-intl";
import Cards from "../../data/Cards.json";
function UsefulLinks() {
  return (
    <div className="usefulLinks">
      {Cards.UsefulLinks.map((card) => (
        <div className="usefulLinks_cards">
          <div className={["usefulLinks_cardsContent","usefulLinks_cardsContentDark"].join(" ")}>
            <div className={["usefulLinks_cardHeader","usefulLinks_cardHeaderDark"].join(" ")}>
              <img src={"/img/OverviewPage/" + card.imageName} alt="" />
              <span>
                {" "}
                <FormattedMessage
                  id={"Overview.links." + card.name + ".Title"}
                  defaultMessage="ERROR"
                />
              </span>
            </div>
            <p>
              <FormattedMessage
                id={"Overview.links." + card.name + ".Description"}
                defaultMessage="ERROR"
              />
            </p>
            <button onClick={() => window.open(card.targetUrl, "_blank")}>
              <FormattedMessage
                id={"Overview.links." + card.name + ".btnTxt"}
                defaultMessage="ERROR"
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default UsefulLinks;
