import React, { useCallback, useLayoutEffect, useState } from "react";
import { FunctionComponent } from "react";
import { GetQueryParameter } from "../../Utilities/Url";
import { GetContext } from "../../Utilities/TeamsContextHandler";
import {
  Provider,
  ThemeInput,
  teamsDarkTheme,
  teamsTheme,
} from "@fluentui/react-northstar";

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: FunctionComponent<ThemeProviderProps> = (props) => {
  const [currentTheme, setTheme] = useState<ThemeInput<any>>(teamsTheme);

  const updateTheme = (theme: string) => {
    document.body.setAttribute("data-theme", theme);
    if (theme === "dark") {
      setTheme(teamsDarkTheme);
    } else {
      setTheme(teamsTheme);
    }
  };

  //reloads page if theme is changed
  const updateThemeLoopThrough = useCallback((theme: string) => {
    window.location.href =
      "/staticTabs/Product?InternalListId=" +
      GetQueryParameter("InternalListId") +
      "&loginHint=" +
      GetQueryParameter("loginHint") +
      "&userObjectId=" +
      GetQueryParameter("userObjectId") +
      "&userPrincipalName=" +
      GetQueryParameter("userPrincipalName") +
      "&tenantId=" +
      GetQueryParameter("tenantId") +
      "&locale=" +
      GetQueryParameter("locale") +
      "&theme=" +
      theme +
      "&userPrincipalName=" +
      GetQueryParameter("userPrincipalName");
    updateTheme(theme);
  }, []);
  useLayoutEffect(() => {
    updateTheme(GetQueryParameter("theme", "light"));
    GetContext((context) => {
      microsoftTeams.registerOnThemeChangeHandler(
        updateThemeLoopThrough.bind(this)
      );
      updateTheme(context.theme);
    });
  }, [updateThemeLoopThrough]);
  return (
    <>
      <Provider theme={currentTheme}>{props.children}</Provider>
    </>
  );
};

export default ThemeProvider;
