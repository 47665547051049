export enum OptionCategory {
    GetStarted = "Get Started",
    KnowledgeBase = "Knowledge base",
    Documentation = "Documentation",
    VideoTrainings = "Video Trainings",
    FAQ = "FAQ",
    CreateTicket = "Create Ticket",
    Roadmap = "Roadmap",
    Ideas = "Ideas",
    Updates = "Updates",
    ReleaseNotes = "Release notes",
    MoreProducts = "More Products",
    About = "About Solutions2Share",
    Feedback = "Feedback",
    TrustCenter = "Trust Center",
    Api = "Api explorer",
    GiveUsFeedback = "Give us feedback",
    Workshop="Workshop",
}