import React, { FunctionComponent } from "react";
import {
    Button,
    PopupIcon,
    Text,
} from "@fluentui/react-northstar";
import cssStyles from "./HeaderBanner.module.scss";
import { FormattedMessage } from "react-intl";

microsoftTeams.initialize();
type HeaderBannerContentTypes = {
    categoryTextId:string,
    categoryTextAlt:string,
    productTitleId:string,
    productTitleAlt:string,
    productDescriptionId:string,
    productDescriptionAlt:string,
    imgUrl:string,
    LearnMore:string;
  }

export const HeaderBannerContent:FunctionComponent<HeaderBannerContentTypes> = ({categoryTextId,categoryTextAlt,productTitleId,productTitleAlt,productDescriptionId,productDescriptionAlt,imgUrl,LearnMore}:HeaderBannerContentTypes) => {
   

    return (
        <div className={cssStyles.HeaderBannerContent}>
            <div className={cssStyles.HeaderBannerControlsWrapper}>


                <div className={cssStyles.InfoArea}>
                <div className={cssStyles.InfoAreaText}>
                    {/* Small info Heading */}
                    <Text
                        weight="semibold"
                        size="small"
                        className={cssStyles.HeaderBannerSmallText}
                        content={
                            <FormattedMessage
                                id={categoryTextId}
                                defaultMessage={categoryTextAlt}
                            ></FormattedMessage>
                        }
                    />

                    {/* External User Manager Heading */}
                    <Text
                        weight="bold"
                        size="larger"
                        content={
                            <FormattedMessage
                                id={productTitleId}
                                defaultMessage={productTitleAlt}
                            ></FormattedMessage>
                        }
                    />

                    {/* Description */}
                    <Text
                        style={{ width: "256px" }}
                        size="medium"
                        content={
                            <FormattedMessage
                                id={productDescriptionId}
                                defaultMessage={productDescriptionAlt}
                            ></FormattedMessage>
                        }
                    /></div>

                    {/* Learn more Button */}
                    <Button
                        className={cssStyles.HeaderBannerLearnMoreButton}
                        content={
                            <>
                                <PopupIcon outline size="medium" style={{ "paddingRight": "5px", "paddingBottom": "2px" }} />
                                <FormattedMessage
                                    id="Common.LearnMore"
                                    defaultMessage={"Learn More"}
                                ></FormattedMessage>
                            </>
                        }
                        primary
                        onClick={() => {
                            window.open(LearnMore, "_blank");
                        }}
                    />
                </div>
                <img draggable={false} className={cssStyles.HeaderBannerImg} src={window.location.origin + imgUrl} alt="Header" />

            </div>
        </div>

    );
};
