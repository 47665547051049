import React from "react";
import ProductContent from "./ProductContent";
import { GetQueryParameter } from "../../Utilities/Url";
import { GetContext } from "../../Utilities/TeamsContextHandler";
import { setSelectedProduct } from "../../Utilities/Product";

export interface IProductState {
  teamsContext: microsoftTeams.Context;
}

export interface IProductProps {
  product: string;
}

export class Product extends React.Component<IProductProps, IProductState> {
  state = {
    teamsContext: {
      entityId: "",
      locale: GetQueryParameter("locale"),
    },
  };
  componentWillMount(): void {
    GetContext((context) => {
      this.setState({ teamsContext: context });
    });
    setSelectedProduct(this.props.product);
  }

  public render() {
    return (
        <ProductContent />
    );
  }
}
