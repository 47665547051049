import React from 'react';
import { Button, PopupIcon, Image } from "@fluentui/react-northstar";
import style from "./ProductCard.module.scss";
import {
  FormattedMessage,
} from "react-intl";
//Properties of Product Card module
type ProductCardProps = {
  title: string,
  text: string,
  translationHeaderID: string,
  translationTextID: string,
  img: string,
  url: string,
}

//Main
export const ProductCard: React.FC<ProductCardProps> = ({ title, text, translationHeaderID, translationTextID, img, url }) => {

  return (

    <>
      <div className={style.ProductCard}>
        <div className={style.BackgroundPicture}>
          <div className={style.ProductImgContainer}>

            {/* Product Card Image */}
            <Image className={style.ProductCardPicture} src={img} style={{ width: "146px", height: "100px" }}></Image>

          </div>
          <div /*className={style.ProductCardTextArea}*/>
            {/* Title */}
            <div className={style.Title}>
              <FormattedMessage id={translationHeaderID} defaultMessage={title} ></FormattedMessage>
            </div>
            {/* Description */}

            <div className={style.Text}>
              <FormattedMessage id={translationTextID} defaultMessage={text}></FormattedMessage>
            </div>

          </div>
        </div>

        <Button className={style.Button} content={<><PopupIcon outline size="medium" style={{ "paddingRight": "5px", "paddingBottom": "2px" }} /><FormattedMessage id="Common.LearnMore" defaultMessage="Learn More"></FormattedMessage></>} primary onClick={() => { window.open(url, "_blank") }} />
      </div>
    </>
  );
}

