import React, { ReactNode } from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import cssStyles from "./SidebarItem.module.scss";

interface SidebarItemProps {
  isActive: boolean;
  onClick: () => void;
  icon: ReactNode;
  title: string;
  titleTranslationId: string;
}

const SidebarItem: FunctionComponent<SidebarItemProps> = (props) => {
  return (
    <li className={[props.isActive ? cssStyles.active : cssStyles.inactive, cssStyles.sidebarItem].join(" ")}>
      <a
        draggable={false}
        href="#"
        className={cssStyles.hyperlink}
        onMouseDown={props.onClick}
      >
        {props.icon}
        <FormattedMessage id={props.titleTranslationId} defaultMessage={props.title} />
      </a>
    </li>
  );
};

export default SidebarItem;
