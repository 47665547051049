import React, { useState, useEffect } from "react";
import cssStyles from "./Product.module.scss";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import {
  CallIcon,
  VolumeIcon,
  NotesIcon,
  PlayIcon,
  RedbangIcon,
  TenantPersonalIcon,
  ToDoListIcon,
  LockIcon,
  CallVideoIcon,
  InfoIcon,
  PlugsIcon,
} from "@fluentui/react-icons-northstar";
import { OptionCategory } from "../../Types/OptionCategory";
import { GetQueryParameter } from "../../Utilities/Url";
import GetStarted from "./pages/GetStarted/GetStarted";
import VideoTraining from "./pages/VideoTrainings/VideoTrainings";
import MoreProducts from "./pages/MoreProducts/MoreProducts";
import AboutS2S from "./pages/AboutS2SContainer/AboutS2S";
import PagesNavBar from "../../GlobalComponents/Nav/Nav";
import Footer from "../../GlobalComponents/Footer/Footer";
import VideoTrainingUser from "./pages/VideoTrainings/VideoTrainingUser";
import GiveUsFeedback from "./pages/GiveUsFeedback/GiveUsFeedback";
import {
  getGeneralInformation,
  getSelectedProductInformation,
  getSelectedProductName,
} from "../../Utilities/Product";
import SidebarItem from "./SidebarItem";
import Workshop from "./pages/Workshop/Workshop";

export interface IProductContentProps {}

export interface IProductContentState {
  option: OptionCategory;
  isInTeams: boolean;
  menuOpen: boolean;
  role: string;
}

const ProductContent: React.FC<IProductContentProps & WrappedComponentProps> = (
  props
) => {
  const [state, setState] = useState<IProductContentState>({
    option: null,
    isInTeams: false,
    menuOpen: false,
    role: GetQueryParameter("role", "admin"),
  });

  useEffect(() => {
    const role = GetQueryParameter("role", "admin");
    setState((prevState) => ({
      ...prevState,
      role,
      option: determineInitialOption(role),
    }));
  }, []);

  const determineInitialOption = (role: string): OptionCategory => {
    const categoryLinks = role === "admin" ? getSelectedProductInformation().categoryLinks : getSelectedProductInformation().categoryLinksUser;

    if (role === "admin") {
      // Prioritize "Get Started" if it's available
      if (categoryLinks.getStarted !== false) {
        return OptionCategory.GetStarted;
      } else if (categoryLinks.knowledgeBase) {
        return OptionCategory.KnowledgeBase;
      } else if (categoryLinks.documentation) {
        return OptionCategory.Documentation;
      } else if (categoryLinks.showVideoTrainings) {
        return OptionCategory.VideoTrainings;
      } else if (categoryLinks.showWorkshop) {
        return OptionCategory.Workshop;
      } else if (categoryLinks.createTicket) {
        return OptionCategory.CreateTicket;
      } else if (categoryLinks.roadmap) {
        return OptionCategory.Roadmap;
      } else if (categoryLinks.ideas) {
        return OptionCategory.Ideas;
      } else if (categoryLinks.updates) {
        return OptionCategory.Updates;
      } else {
        return OptionCategory.GetStarted; // Fallback to GetStarted if nothing else is available
      }
    } else {
      if (categoryLinks.documentation) {
        return OptionCategory.Documentation;
      } else if (categoryLinks.showVideoTrainings) {
        return OptionCategory.VideoTrainings;
      } else {
        return OptionCategory.About;
      }
    }
  };


  const getWebsiteIfNotEmpty = (valueThatCanBeEmpty: any, returnValue: any) => {
    if (valueThatCanBeEmpty) {
      return returnValue;
    } else {
      setState((prevState) => ({
        ...prevState,
        option: OptionCategory.GetStarted,
      }));
    }
  };

  const renderFrame = (option: OptionCategory) => {
    let frameWebsite: string | null = null;
    let component: React.ReactElement | null = null;

    switch (option) {
      case OptionCategory.GetStarted:
        component = <GetStarted />;
        break;
      case OptionCategory.KnowledgeBase:
        frameWebsite = getWebsiteIfNotEmpty(
          state.role === "admin"
            ? getSelectedProductInformation().categoryLinks.knowledgeBase
            : "",
          getSelectedProductInformation().categoryLinks.knowledgeBase
        );
        break;
      case OptionCategory.Documentation:
        frameWebsite = getWebsiteIfNotEmpty(
          state.role === "admin"
            ? getSelectedProductInformation().categoryLinks.documentation
            : getSelectedProductInformation().categoryLinksUser.documentation,
          state.role === "admin"
            ? getSelectedProductInformation().categoryLinks.documentation
            : getSelectedProductInformation().categoryLinksUser.documentation
        );
        break;
      case OptionCategory.VideoTrainings:
        component =
          state.role === "admin"
            ? getWebsiteIfNotEmpty(
                getSelectedProductInformation().categoryLinks
                  .showVideoTrainings,
                <VideoTraining />
              )
            : getWebsiteIfNotEmpty(
                getSelectedProductInformation().categoryLinks
                  .showVideoTrainings,
                <VideoTrainingUser />
              );
        break;
      case OptionCategory.CreateTicket:
        frameWebsite = getWebsiteIfNotEmpty(
          getSelectedProductInformation().categoryLinks.createTicket,
          getSelectedProductInformation().categoryLinks.createTicket
        );
        break;
      case OptionCategory.Roadmap:
        frameWebsite = getWebsiteIfNotEmpty(
          getSelectedProductInformation().categoryLinks.roadmap,
          getSelectedProductInformation().categoryLinks.roadmap
        );
        break;
      case OptionCategory.Ideas:
        frameWebsite = getWebsiteIfNotEmpty(
          getSelectedProductInformation().categoryLinks.ideas,
          getSelectedProductInformation().categoryLinks.ideas
        );
        break;
      case OptionCategory.Updates:
        frameWebsite = getWebsiteIfNotEmpty(
          getSelectedProductInformation().categoryLinks.updates,
          getSelectedProductInformation().categoryLinks.updates
        );
        break;
      case OptionCategory.MoreProducts:
        component = <MoreProducts />;
        break;
      case OptionCategory.About:
        component = <AboutS2S />;
        break;
      case OptionCategory.GiveUsFeedback:
        component = <GiveUsFeedback productName={getSelectedProductName()} />;
        break;
      case OptionCategory.Workshop:
        component = <Workshop />;
        break;
      case OptionCategory.TrustCenter:
        frameWebsite = getWebsiteIfNotEmpty(
          getSelectedProductInformation().categoryLinks.showTrustCenter,
          getGeneralInformation().trustCenter
        );
        break;
      case OptionCategory.Api:
        frameWebsite = `${
          window.location.origin
        }/api/index.html?theme=${GetQueryParameter("theme")}`;
        break;
      default:
        break;
    }

    return component ? (
      <>{component}</>
    ) : (
      <div className={cssStyles.iFrameStyles}>
        <iframe
          className={cssStyles.iFrameStyles}
          src={frameWebsite ?? ""}
          title="Content"
        />
      </div>
    );
  };

  const renderSidebarItems = () => {
    const { role } = state;
    const settingsItems: React.ReactElement[] = [];
    const informationItems: React.ReactElement[] = [];

    const createSidebarItem = (
      option: OptionCategory,
      icon: React.ReactElement,
      title: string,
      titleTranslationId: string
    ) => (
      <SidebarItem
        key={option}
        isActive={state.option === option}
        onClick={() => setState((prevState) => ({ ...prevState, option }))}
        icon={icon}
        title={title}
        titleTranslationId={titleTranslationId}
      />
    );

    if (role === "admin") {
      settingsItems.push(
        createSidebarItem(
          OptionCategory.GetStarted,
          <PlayIcon outline className={cssStyles.icons} />,
          "Get started*",
          "SideBar.Header.GetStarted"
        ),
        getSelectedProductInformation().categoryLinks.knowledgeBase &&
          createSidebarItem(
            OptionCategory.KnowledgeBase,
            <InfoIcon outline className={cssStyles.icons} />,
            "Knowledge base*",
            "SideBar.Header.KnowledgeBase"
          ),
        getSelectedProductInformation().categoryLinks.documentation &&
          createSidebarItem(
            OptionCategory.Documentation,
            <NotesIcon outline className={cssStyles.icons} />,
            "Documentation*",
            "SideBar.Header.Documentation"
          ),
        getSelectedProductInformation().categoryLinks.showVideoTrainings &&
          createSidebarItem(
            OptionCategory.VideoTrainings,
            <CallVideoIcon outline className={cssStyles.icons} />,
            "Video trainings*",
            "SideBar.Header.VideoTrainings"
          ),
        getSelectedProductInformation().categoryLinks.showWorkshop &&
          createSidebarItem(
            OptionCategory.Workshop,
            <NotesIcon outline className={cssStyles.icons} />,
            "Workshop*",
            "SideBar.Header.Workshop"
          ),
        getSelectedProductInformation().categoryLinks.showAPIDocs &&
          createSidebarItem(
            OptionCategory.Api,
            <PlugsIcon outline className={cssStyles.icons} />,
            "API*",
            "SideBar.Header.Api"
          ),
        getSelectedProductInformation().categoryLinks.createTicket &&
          createSidebarItem(
            OptionCategory.CreateTicket,
            <CallIcon outline className={cssStyles.icons} />,
            "Create ticket*",
            "SideBar.Header.CreateTicket"
          )
      );

      informationItems.push(
        getSelectedProductInformation().categoryLinks.roadmap &&
          createSidebarItem(
            OptionCategory.Roadmap,
            <ToDoListIcon outline className={cssStyles.icons} />,
            "Roadmap*",
            "SideBar.Header.Roadmap"
          ),
        getSelectedProductInformation().categoryLinks.ideas &&
          createSidebarItem(
            OptionCategory.Ideas,
            <RedbangIcon outline className={cssStyles.icons} />,
            "Ideas*",
            "SideBar.Header.Ideas"
          ),
        getSelectedProductInformation().categoryLinks.updates &&
          createSidebarItem(
            OptionCategory.Updates,
            <VolumeIcon outline className={cssStyles.icons} />,
            "Updates*",
            "SideBar.Header.Updates"
          ),
        createSidebarItem(
          OptionCategory.MoreProducts,
          <TenantPersonalIcon outline className={cssStyles.icons} />,
          "More products*",
          "SideBar.Header.MoreProducts"
        ),
        createSidebarItem(
          OptionCategory.TrustCenter,
          <LockIcon outline className={cssStyles.icons} />,
          "Trust center*",
          "SideBar.Header.TrustCenter"
        ),
        createSidebarItem(
          OptionCategory.About,
          <InfoIcon outline className={cssStyles.icons} />,
          "About*",
          "SideBar.Header.About"
        )
      );
    } else {
      settingsItems.push(
        getSelectedProductInformation().categoryLinksUser.documentation &&
          createSidebarItem(
            OptionCategory.Documentation,
            <NotesIcon outline className={cssStyles.icons} />,
            "Documentation*",
            "SideBar.Header.Documentation"
          ),
        getSelectedProductInformation().categoryLinksUser.showVideoTrainings &&
          createSidebarItem(
            OptionCategory.VideoTrainings,
            <CallVideoIcon outline className={cssStyles.icons} />,
            "Video trainings*",
            "SideBar.Header.VideoTrainings"
          ),
        getSelectedProductInformation().showTrustCenter &&
          createSidebarItem(
            OptionCategory.TrustCenter,
            <LockIcon outline className={cssStyles.icons} />,
            "Trust center*",
            "SideBar.Header.TrustCenter"
          )
      );

      informationItems.push(
        createSidebarItem(
          OptionCategory.About,
          <InfoIcon outline className={cssStyles.icons} />,
          "About*",
          "SideBar.Header.About"
        )
      );
    }

    // Filter out null/undefined items
    const filteredSettingsItems = settingsItems.filter(Boolean);
    const filteredInformationItems = informationItems.filter(Boolean);

    return (
      <>
        {filteredSettingsItems.length > 0 && (
          <>
            <label key="Settings" className={cssStyles.navHeader}>
              <FormattedMessage
                id="SideBar.Header.Settings"
                defaultMessage="Settings*"
              />
            </label>
            {filteredSettingsItems}
          </>
        )}
        {filteredInformationItems.length > 0 && (
          <>
            <label key="Information" className={cssStyles.navHeader}>
              <FormattedMessage
                id="SideBar.Header.Information"
                defaultMessage="Information*"
              />
            </label>
            {filteredInformationItems}
          </>
        )}
      </>
    );
  };

  return (
    <div className={cssStyles.background}>
      <PagesNavBar targetUrl="/Overview" show={!state.isInTeams} />
      <div className={cssStyles.gridContainer}>
        <input
          type="checkbox"
          className={cssStyles.MenuButton}
          tabIndex={0}
          id="MenuButton"
          onClick={() => document.getElementById("MenuButton")?.focus()}
        />
        <div
          className={cssStyles.MenuButtonWrapper}
          onClick={() => document.getElementById("MenuButton")?.focus()}
        >
          <div className={cssStyles.MenuButtonLineWrapper}>
            <div className={cssStyles.MenuButtonLine} />
          </div>
        </div>
        <div className={cssStyles.gridMenu}>
          <ul className={cssStyles.navList}>{renderSidebarItems()}</ul>
        </div>
        <div className={cssStyles.gridMain}>{renderFrame(state.option)}</div>
      </div>
      <Footer />
    </div>
  );
};

export default injectIntl(ProductContent);
