import { FunctionComponent } from "react";
import cssStyles from "./TextArea.module.scss";
import React from "react";
import { WrappedComponentProps, injectIntl } from "react-intl";

interface TextAreaProps {
  placeholderTextId?: string;
  altPlaceholderText?: string;
  className?: string;
}

const TextArea: FunctionComponent<TextAreaProps & WrappedComponentProps> = (
  props: TextAreaProps & WrappedComponentProps
) => {
  return (
    <textarea
      className={[cssStyles.TextArea,props.className].join(" ")}
      placeholder={props.placeholderTextId && props.intl.formatMessage({
        id: props.placeholderTextId,
        defaultMessage: props.altPlaceholderText,
      })}
    />
  );
};

export default injectIntl(TextArea);
