import * as React from "react";
import Expertise from "./components/Expertise/Expertise";
import Frame from "./components/Frame/Frame";
import HeadPicture from "./components/HeadPicture/HeadPicture";
import OurStory from "./components/OurStory/OurStory";
import Partner from "./components/Partner/Partner";
import Team from "./components/Team/Team";

interface Props {
}

class AboutS2S extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <HeadPicture/>
        <OurStory/>
        <Frame/>
        <Expertise/>
        <Partner />
        <Team/>
      </>
    );
  }
}

export default AboutS2S;
