import * as React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import style from "../../AboutS2S.module.scss";


interface ExpertiseProps {
}

class Expertise extends React.Component<ExpertiseProps & WrappedComponentProps> {
    render() {
        return (
            <>
                <h2><FormattedMessage id="AboutS2S.OurExpertise.Title" defaultMessage="Our Expertise" /></h2>
                <div className={style.expertiseText}>
                    <p className={style.textLightmode}>
                        <FormattedMessage id="AboutS2S.OurExpertise.Description" defaultMessage="We are a Microsoft Gold Partner with certified knowledge and expertise in solutions for collaboration and content. 
                In addition we have also received the Rencore Development Best Practices certification, confirming that our development 
                processes and software solutions comply to high global standards regarding their quality, security and safety."/>
                    </p>
                </div>
            </>
        );
    }
}

export default injectIntl(Expertise);