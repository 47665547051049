import React from "react";
import OverviewPageContent from "./OverviewPageContent"

interface OverviewPageState {
  teamsContext: microsoftTeams.Context;
  isTeamsContext: boolean;
}

class OverviewPage extends React.Component<any, OverviewPageState> {

  render() {
    
    return (
        <OverviewPageContent></OverviewPageContent>
    );
  }
}

export default OverviewPage;