import React from "react";
import { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import cssStyles from "../../OverviewPageContent.module.scss";
import Artwork from "../Artwork/Artwork";

const OverviewHeader: FunctionComponent = () => {
    return (
        <div className={cssStyles.OverviewHeader}>
            <div className={cssStyles.RectContainer}>
                <div className={cssStyles.OverviewHeaderRect1}></div>
                <div className={cssStyles.OverviewHeaderRect2}></div>
            </div>

            <div className={cssStyles.OverviewHeaderContent}>
                <div className={cssStyles.OverviewHeaderText}>
                    <div className={cssStyles.OverviewHeaderText1}><FormattedMessage id='Overview.Header.Text1' defaultMessage='Welcome!' /> </div>
                    <div className={cssStyles.OverviewHeaderText2}><FormattedMessage id='Overview.Header.Text2' defaultMessage='Do more in Teams with our apps' /> </div>
                    <div className={cssStyles.OverviewHeaderText3}><FormattedMessage id='Overview.Header.Text3' defaultMessage='Explore Solutions2Share solutions around Microsoft Teams' /> </div>
                </div>
                <div className={cssStyles.OverviewHeaderArtwork}>
                    <Artwork></Artwork>
                </div>
            </div>
        </div>
    );
}

export default OverviewHeader;