import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface FooterItemProps {
    defaultText?:string;
    multilingualTextId:string;
    webUrl:string;
    className:string;
}
 
const FooterItem: React.FunctionComponent<FooterItemProps> = (props) => {
    return ( 
        <a draggable={false} href={props.webUrl} className={props.className}>
            <FormattedMessage id={props.multilingualTextId} defaultMessage={props.defaultText}/>
        </a>
    );
}
 
export default FooterItem;