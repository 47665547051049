import React from "react";
import { FunctionComponent } from "react";
import Dropdown from "../Dropdown/Dropdown";
import productsJson from "../../MainTab/ProductWindow/Products.json";
import cssStyle from "./ProductsDropdown.module.scss";
import { ChevronDownIcon } from "@fluentui/react-northstar";
import { getSelectedProductInformation, getSelectedProductName } from "../../Utilities/Product";
import { changeQueryParameter, getAllQueryParameters, setNthUrlPart } from "../../Utilities/Url";
import { Link } from "react-router-dom";

interface ProductsDropdownProps {}

const ProductsDropdown: FunctionComponent<ProductsDropdownProps> = () => {
  return (
    <Dropdown
      title={
        <div className={cssStyle.title}>
          <div className={cssStyle.contentHolder}>
            <img
              className={cssStyle.icon}
              src={
                window.location.origin +
                getSelectedProductInformation().information.icon
              }
              alt={getSelectedProductName()}
            />
            <div className={cssStyle.productName}>
              {getSelectedProductInformation().information.title}
            </div>
          </div>
          <ChevronDownIcon></ChevronDownIcon>
        </div>
      }
    >
      <div className={cssStyle.dropdownBackground}>
        {Object.entries(productsJson.Products).map((productSection) => (
          <Link
            key={productSection[0]}
            className={cssStyle.link}
            to={{pathname: setNthUrlPart(window.location.pathname, 1, productSection[0]), search: getAllQueryParameters(window.location.href)}}
          >
            <img
              className={cssStyle.icon}
              src={
                window.location.origin +
                productSection[1].information.icon
              }
              alt={productSection[0]}
            />
            <div className={cssStyle.productName}>
                {productSection[1].information.title}
            </div>
          </Link>
        ))}
      </div>
    </Dropdown>
  );
};

export default ProductsDropdown;
