import * as React from "react";

import { VideoCard } from "./components/VideoCard/VideoCard";
import cssStyles from "./VideoTrainings.module.scss";
import Videos from "./Videos.json";
import { checkLanguageGerman } from "../../../../Utilities/Language";

interface VideoTrainingProps {}

const VideoTraining: React.FunctionComponent<VideoTrainingProps> = (
  props: VideoTrainingProps
) => {
  const videos = [].concat(...Object.values(Videos));
  return (
    <div className={cssStyles.VideoCardGrid}>
      {videos.map((video) => (
        <VideoCard
          text={video.altText}
          translationTextID={video.translationId}
          videoID={
            checkLanguageGerman() ? video.germanVideoId : video.englishVideoId
          }
        ></VideoCard>
      ))}
    </div>
  );
};

export default VideoTraining;
