
import React from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import cssStyles from "./OverviewPageContent.module.scss";
import Cards from "./data/Cards.json";
import NavCategories from "./data/NavCategories.json";
import LanguageDropdown from "../../GlobalComponents/LanguageDropdown/LanguageDropdown";
import OverviewHeader from "./components/Header/OverviewHeader";
import OverviewCard from "./components/OverviewCard/OverviewCard";
import { getAllQueryParameters } from "../../Utilities/Url";
import Footer from "../../GlobalComponents/Footer/Footer";
import UsefulLinks from "./components/UsefulLinks/UsefulLinks";
import { isInTeams } from "../../Utilities/TeamsContextHandler";

interface OverviewpageContentProps {
}

interface OverviewpageContentState {
  filteredCards: any[];
  selectedNavCategory: number;
  headerButtonToggle: string;
}

class OverviewpageContent extends React.Component<
  OverviewpageContentProps & WrappedComponentProps,
  OverviewpageContentState
> {
  constructor(props: OverviewpageContentProps & WrappedComponentProps) {
    super(props);
    this.state = {
      filteredCards: [],
      selectedNavCategory: 0,
      headerButtonToggle: "overview",
    };
  }

  filterCards(filterFor: string) {
    var cards = [];
    if (filterFor === "all") {
      cards = Cards.Overview;
    } else {
      cards = Cards.Overview.filter((card) => card.categoryId === filterFor);
    }
    this.setState({ filteredCards: cards });
  }

  componentDidMount() {
    this.filterCards("all");
  }

  setHeaderButtonToggle(toggle: string) {
    this.setState({ headerButtonToggle: toggle });
  }

  render() {
    return (
      <div className={cssStyles.OverviewpageContent}>
        {/* <PagesNavBar targetUrl={this.props.originUrl} theme={this.props.theme} show={true}></PagesNavBar> */}
        <div className={cssStyles.Content}>
          {!isInTeams() && (
            <div className={cssStyles.Topbar}>
              <div>
                <h6>Solutions2Share</h6>
                <div
                  className={[
                    cssStyles.OverviewPageTitle,
                    cssStyles.OverviewPageTitleDark,
                  ].join(" ")}
                >
                  {/* <FormattedMessage id='Overview.Title' defaultMessage='Overview' /> */}
                  <button
                    className={
                      this.state.headerButtonToggle === "overview" &&
                      cssStyles.activeTab
                    }
                    onClick={() => this.setHeaderButtonToggle("overview")}
                  >
                    Overview
                  </button>
                  <button
                    className={
                      this.state.headerButtonToggle === "usefulLinks" &&
                      cssStyles.activeTab
                    }
                    onClick={() => this.setHeaderButtonToggle("usefulLinks")}
                  >
                    Useful links
                  </button>
                </div>
              </div>
              <LanguageDropdown></LanguageDropdown>
            </div>
          )}
          <OverviewHeader></OverviewHeader>
          {this.state.headerButtonToggle === "overview" && (
            <div className={cssStyles.navBar}>
              {NavCategories.map((navCategory, index) => (
                <div
                  onClick={
                    index !== this.state.selectedNavCategory
                      ? () => {
                          this.setState({ selectedNavCategory: index });
                          this.filterCards(
                            navCategory.id.substring(
                              navCategory.id.indexOf(".") + 1
                            )
                          );
                        }
                      : null
                  }
                  className={cssStyles.navBarCategoryItem}
                  id={
                    index === this.state.selectedNavCategory
                      ? cssStyles.selectedNavCategory
                      : null
                  }
                >
                  <FormattedMessage
                    id={navCategory.id}
                    defaultMessage={navCategory.id}
                  />
                </div>
              ))}
            </div>
          )}

          {this.state.headerButtonToggle === "overview" ? (
            <div className={cssStyles.OverviewCardContainer}>
              {this.state.filteredCards.map((card) => (
                <OverviewCard
                  TargetUrl={card.targetUrl}
                  LinkParams={getAllQueryParameters(window.location.href)}
                  TitleTranslationId={"Overview.Card." + card.name + ".Title"}
                  DescriptionTranslationId={
                    "Overview.Card." + card.name + ".Description"
                  }
                  CategoryTranslationId={"Common." + card.categoryId}
                  Company={card.company}
                  Image={"/img/OverviewPage/" + card.imageName}
                />
              ))}
            </div>
          ) : (
            <div className={cssStyles.OverviewCardsBlock}>
              <UsefulLinks />
            </div>
          )}
        </div>
        <Footer></Footer>
      </div>
    );
  }
}

export default injectIntl(OverviewpageContent);
