import React, { useState } from "react";
import cssStyles from "./HeaderBanner.module.scss";
import { ChevronEndIcon, ChevronStartIcon } from "@fluentui/react-northstar";
import Products from "../../ProductsList.json"
import { HeaderBannerContent } from "./HeaderBannerContent";
import { checkLanguageGerman } from "../../../../../../Utilities/Language";
export const HeaderBanner = () => {
  const [selectedHeaderContent, setSelectedHeaderContent] = useState(0)

  const leftButtonPressed = () => {
    setSelectedHeaderContent(selectedHeaderContent - 1)
  }

  const rightButtonPressed = () => {
    setSelectedHeaderContent(selectedHeaderContent + 1)
  }

  const dotPressed = (index: number) => {
    setSelectedHeaderContent(index)
  }

  return (
    <div className={cssStyles.HeaderBanner}>
      <div className={cssStyles.HeaderBannerContentWrapper}>
        <div className={cssStyles.HeaderBannerContent}>
          {
            <HeaderBannerContent
              categoryTextId={Products.HeaderContents[selectedHeaderContent].categoryTextId}
              categoryTextAlt={Products.HeaderContents[selectedHeaderContent].categoryTextAlt}
              productDescriptionAlt={Products.HeaderContents[selectedHeaderContent].productDescriptionAlt}
              productDescriptionId={Products.HeaderContents[selectedHeaderContent].productDescriptionId}
              productTitleAlt={Products.HeaderContents[selectedHeaderContent].productTitleAlt}
              productTitleId={Products.HeaderContents[selectedHeaderContent].productTitleId}
              imgUrl={Products.HeaderContents[selectedHeaderContent].img}
              LearnMore={ checkLanguageGerman()?Products.HeaderContents[selectedHeaderContent].germanUrl:Products.HeaderContents[selectedHeaderContent].englishUrl}
            />
          }
          <div className={cssStyles.HeaderBannerDots}>
            {Products.HeaderContents.map((_, index) => (
              <button onClick={() => dotPressed(index)} className={[cssStyles.Dot,index === selectedHeaderContent?cssStyles.ActiveDot:""].join(" ")}></button>
            ))}
          </div>
        </div>
        <div className={cssStyles.HeaderBannerControls}>
          <button disabled={selectedHeaderContent === 0} className={cssStyles.HeaderBannerLeftButton} onClick={leftButtonPressed} >
            <ChevronStartIcon />
          </button>
          <button disabled={selectedHeaderContent === Products.HeaderContents.length - 1} className={cssStyles.HeaderBannerRightButton} onClick={rightButtonPressed}>
            <ChevronEndIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
